import { useQuery } from 'react-query';

import api from 'services/api';

interface ContactItemResponse {
  id: number;
  nome: string;
  sobreNome: string;
  empresa: string;
  email: string;
  endereco: string;
  cep: string;
  cidade: string;
  estado: string;
  pais: string;
  telefone: string;
  cadastro: string;
  status: string;
  validarIdentidade: boolean;
  dominios: {
    idDominio: number;
    dominio: string;
    status: string;
  }[];
}

interface ContactsResponse {
  data: ContactItemResponse[];
}

interface InternationalContact {
  id: number;
  name: string;
  lastName: string;
  company: string;
  email: string;
  address: string;
  zipCode: string;
  city: string;
  state: string;
  country: string;
  countryCode: string;
  phone: string;
  validateIdentity: boolean;
  domains: {
    idDominio: number;
    dominio: string;
    status: string;
  }[];
}

function formatContact(contactResponse: ContactItemResponse) {
  return {
    id: contactResponse.id,
    name: contactResponse.nome,
    lastName: contactResponse.sobreNome,
    company: contactResponse.empresa,
    email: contactResponse.email,
    address: contactResponse.endereco,
    zipCode: contactResponse.cep,
    city: contactResponse.cidade,
    state: contactResponse.estado,
    country: contactResponse.pais,
    countryCode: contactResponse.telefone.split('.')[0],
    phone: contactResponse.telefone.split('.')[1],
    validateIdentity: contactResponse.validarIdentidade,
    domains: contactResponse.dominios,
  };
}

async function loadInternationalDomainContacts() {
  const { data } = await api.get<ContactsResponse>(`enom/v1/contato`, {
    params: {
      listarValidacaoDeIdentidade: 1,
    },
  });

  const contacts = data.data.map(formatContact);

  return contacts;
}

export default function useInternationalDomainContacts(idContact?: number) {
  const initialData: InternationalContact[] = [];

  const { data, isError, isLoading, isFetching, refetch, isFetched } = useQuery<
    InternationalContact[]
  >('internationalDomainContacts', () => loadInternationalDomainContacts(), {
    refetchOnWindowFocus: false,
    staleTime: 86400000,
    placeholderData: initialData,
    retry: false,
  });

  const contacts = data || initialData;

  const filteredContacts = idContact
    ? contacts.filter(contact => contact.id === idContact)
    : contacts;

  return {
    contacts: filteredContacts,
    loading: isLoading,
    fetching: isFetching,
    error: isError,
    refetch,
    isFetched,
  };
}
